.dateSearch {
  position: relative;
  top: 8px;
}

.IndexDataTableNeoParentScroll {
  display: block;
  width: 100%;
  overflow: auto;

  .scroollDataTableNeoScroll {
    white-space: initial;
    width: 100%;
    display: table;
    overflow: auto;
    flex-wrap: nowrap !important;
    .CardDateTableScroll {
      // width: 137%;
      width:max-content;
      min-width: 128%;
      .IndexDatatableNeoRowScroll {
        display: flex;
        white-space: wrap;
        overflow-y: visible;
        flex-wrap: nowrap!important;
        width: 100%;
        max-width: -webkit-fill-available;

        .IndexDatatableNeoCol {
          text-align: left;
          padding-left: 100px;
        }
        
      }
    }
  }
}

.IndexDatatableNeoRow {
  display: flex;
  white-space: initial;
  flex-wrap: nowrap !important;
}
.IndexDatatableNeoCol {
  text-align: left;
  padding-left: 50px;
}
.IndexDatatableNeoCol1 {
  text-align: left;
  padding-left: 43px;
}
@media (max-width:640px){
  .IndexDataTableNeoParent {
    display: block;
    width: 100%;
    overflow: auto;

    .scroollDataTableNeo {
      // display: contents;
      white-space: initial;
      // overflow-y: hidden;

      width: 100%;
      display: table;
      overflow: auto;
      flex-wrap: nowrap !important;
      // padding: 0px 15px;

      .CardDateTable {
        width: max-content;
        min-width: 200%;
        .IndexDatatableNeoRowScroll,
        .IndexDatatableNeoRow {
          display: flex;
          white-space: nowrap;
          overflow-y: visible;
          flex-wrap: nowrap !important;
          width: 100%;
          max-width: -webkit-fill-available;

          .IndexDatatableNeoCol {
            text-align: left;
            padding-left: 100px;
          }
        }
      }
    }
  }
}