.quickStylesBar {
  margin: 24px;
  // .QuickFiltersButtons{
    Button {
      height: 26px;
      line-height: 0px;
      font-size: 12px;
      margin: 8px;
    }
    
  // }
  .MuiPickerStaticWrapper-root{
    // .PrivatePickersSlideTransition-root{
      .MuiTypography-caption{
        font-size: 13px;
        font-family: Roboto;
        font-weight: 900;
        font-style: normal;
      }
      Button{
        height: 10pxpx;
        line-height: 5px;
        font-size: 12px;
        margin: 0px;
        font-family: Roboto;
        font-weight: 400;
        font-style: normal;
      }
    // }
  }
  
  .delayed {
    background-color: #ffe3e1;
    color: #e53d2c !important;
  }
  .untracked {
    background-color: #ffaba2;
    color: #ac1000 !important;
  }
  .reset {
    background-color: #0ab38c;
    color: #ffffff !important;
  }
}
.iconButtons {
  background-color: #f1f5f7;
}

//status text
.actionText {
  font-weight: 700;
  font-size: 13px;
}

//action dropdown
.dropbtn {
  color: white;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: fixed;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content div:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.action-button {
  cursor: pointer;
  color: #2800fc !important;
}

.action-button-disabled {
  pointer-events: none !important;
  opacity: 0.7;
  color: #2800fc !important;
}

.action-button-cancel {
  cursor: pointer;
  color: #e53d2c !important;
}

//calender styles
.quickStylesBar {
  .MuiPickerStaticWrapper-root {
    position: fixed;
    z-index: 9999;
    direction: ltr !important;
  }
}
