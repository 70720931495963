
.invoiceStyle {

    .input-group > .form-control {
      height: 24px !important;
      padding-left: 0px !important;
      border-style: none !important;
    }
      // .input-group > .form-control:hover,
      // .input-group > .form-control:focus {
      //   border-style: none !important;
      //   height: 22px !important;
      // }
      // .input-border:hover,
      // .input-border:focus {
      //   border: 2px solid #0000ff !important;
      //   height: 26px !important;
      //   z-index: 100;
      // }
    .input-group {
      width: 95% !important;
    }
    .input-border {
      height: 26px !important;
      border: 0.5px solid #aeaeae !important;
      border-radius: 5px !important;
    }
    .input-line {
      margin-top: 3px;
      // margin-left: -5px;
      height: 20%;
      z-index: 1;
      border-left: 2px solid #0000ff;
    }
    .span-img {
      width: 15px;
      height: 15px;
      // margin-top: 10px;
      z-index: 1;
      left: 50% !important;
      cursor: pointer;
    }
  
    #typeVehicleList {
      width: 100%;
      margin-top: 8px;
    
    }
    
    .react-select__indicator ,
    .react-select__dropdown-indicator{
      padding: 4px !important;
    }
    .react-select__placeholder {
      top: 41% !important;
    }

    .dangerSpan {
      color: red;
    }
  
    @media (max-width: 620px) {
      .cardStyleI  {
       display: flex;
       flex-wrap: wrap;
      }
      
    }
    @media (max-width: 620px) {
      .cardStyleI .filter  { 
        flex: 1 0 40%;
        margin-left: 0!important;
      }
      
    }
    
  }
  